import React from "react";
import LazyLoad from "react-lazyload";
import { graphql, Link } from "gatsby";
import { withContext } from "~context";
import { BreadCrumbs } from "~components/assessment/bread-crumbs";
import { AssessmentVoiceList } from "~components/assessment/voice-list";
import { Assessment2PaneLayout } from "~components/templates/assessment-2pane-layout/page";
import "./index.scss";
import { PriceDocBanner } from "~components/assessment/price_doc_banner";
import { AssessmentDocContact } from "~components/assessment/price_doc_contact";
import { DocumentStyle } from "~components/templates/document-style";
import { Common2PaneSectionLayout } from "~components/templates/common-2pane-layout/section-layout";
import { Section } from "~components/templates/section";
import { TableStyle } from "~components/templates/table-style";
import { Interview } from "~components/utils/interview";

type Props = {
  data: GatsbySpaPageQuery;
};

const Component: React.FC<Props> = (props) => {
  const { data } = props;
  const CONTACT_FORM_ID =
    "31lfBkuItiC6zUlXxuv94ntAG0HPusBDbhRIM9Ck5f5X7VEJlguDsXpREs7pipVn5";

  return (
    <>
      <PriceDocBanner />
      <Assessment2PaneLayout
        formId={CONTACT_FORM_ID}
        meta={{
          title: "SPA診断",
          description:
            "Webアプリケーションのセキュリティ診断観点に、SPA利用時に起こりうる特有の観点を加え、専門のセキュリティエンジニアが診断します。",
        }}
      >
        <div className="Spa AssessmentCommonPage">
          <LazyLoad>
            <div className="AssessmentCommonTop SpaTop">
              <div className="AssessmentCommonTop_Wrapper">
                <Common2PaneSectionLayout>
                  <DocumentStyle width="no-limit">
                    <h1>SPA診断</h1>
                    <p>
                      SPAで構築されたWebアプリケーションについて、フロントエンド部分のソースコードを読むことによるホワイトボックス形式の診断を提供します。ブラックボックス形式のWebアプリケーション診断でフロントエンドを診断する際には発見する難易度が高い脆弱性も検出することができます。
                    </p>
                  </DocumentStyle>
                </Common2PaneSectionLayout>
              </div>
            </div>
          </LazyLoad>

          <BreadCrumbs title="SPA診断" />

          <Section background="subtle-gray">
            <Common2PaneSectionLayout>
              <DocumentStyle>
                <h2>SPA特有のセキュリティリスク</h2>
                <p>
                  React.jsやVue.jsに代表されるSPAフレームワークは近年急速に普及が進みました。しかし、フロントエンドでのリッチな処理と引き換えに発生したDOM
                  based
                  XSSといったセキュリティリスクは見逃されてしまいがちです。
                  <br />
                  <br />
                  SPAに発生するそのような脆弱性のうち、非自明で検出難易度が高いものはホワイトボックス形式の診断でないと発見することが難しいことがあります。
                  <br />
                  <br />
                  例えば、GitLabが使用していたMermaid
                  chartには、ユーザーのディレクティブを適切にエスケープせずにそのまま使用していたためにDOM
                  based
                  XSSが存在していました。この脆弱性は単純にペイロードを流し込むだけでは発火せず、ホワイトボックス的にコードを読むことで発見されています。
                  <br />
                  <br />
                  参考:
                  <a href="https://hackerone.com/reports/1103258">
                    https://hackerone.com/reports/1103258
                  </a>
                </p>
              </DocumentStyle>
            </Common2PaneSectionLayout>
          </Section>

          <LazyLoad>
            <Section>
              <Common2PaneSectionLayout>
                <DocumentStyle>
                  <h2>
                    SPAを用いたWebアプリケーションに対するセキュリティ診断
                  </h2>
                  <p>
                    通常のブラックボックス形式のWebアプリケーション診断はフロントエンドも診断対象にしています。
                  </p>
                  <img src={data?.spa_blackbox?.publicURL || ""} alt="" />
                  <p>
                    ですが、ブラックボックス形式の診断には限界があり、上記のGitLab事例のようにSPAの一部の非自明な脆弱性に関しては発見できないことがあります。そのような脆弱性も含めて網羅的にリスクを洗い出したいというお客様向けに提供しているのがこのSPA診断です。
                  </p>
                  <img src={data?.spa_whitebox?.publicURL || ""} alt="" />
                  <h3>提供をご依頼するデータ</h3>
                  <p>
                    SPA診断をご利用の際には、フロントエンドのソースコードをご提供いただきます。ソースコードを専門の診断員が読み、精査することで、診断対象の
                    SPAに脆弱性が存在しないかを診断します。
                  </p>
                  <h3>SPA診断のユースケース</h3>
                  <p>
                    基本的にSPA診断単体でのご利用は想定していません。Webアプリケーション診断をご利用いただき、バックエンドの診断およびフロントエンドの一般的な診断をご利用いただいた上で、より踏み込んでフロントエンドを検証するためのオプションメニューとなっています。
                  </p>
                </DocumentStyle>
              </Common2PaneSectionLayout>
            </Section>
          </LazyLoad>

          <LazyLoad>
            <Section>
              <Common2PaneSectionLayout>
                <DocumentStyle>
                  <h2>診断観点</h2>
                  <h5>SPA診断において発見可能なフロントエンドの脆弱性です。</h5>
                  <TableStyle>
                    <table>
                      <tbody>
                        <tr>
                          <th>DOM based XSS</th>
                          <td>
                            SPAのJavaScriptがユーザーの入力値を適切に処理しないことにより、外部から注入された悪意あるスクリプトが実行されてしまわないか検査します。
                          </td>
                        </tr>
                        <tr>
                          <th>CSSインジェクション</th>
                          <td>
                            外部からスタイルシートもしくは&#0060;style&#0062;タグを注入できてしまうことによりHTML内部のデータが漏洩しないか検査します。
                          </td>
                        </tr>
                        <tr>
                          <th>オープンリダイレクト</th>
                          <td>
                            外部から与えられたパラメータを利用してリダイレクト先を決める機能において、任意のリダイレクト先を指定できる脆弱性がないか検査します。
                          </td>
                        </tr>
                        <tr>
                          <th>クライアントサイドDoS</th>
                          <td>
                            不正な入力やエラーハンドリングの不備などにより、サービスの利用を妨げる脆弱性がないか検査します。
                          </td>
                        </tr>
                        <tr>
                          <th>クライアントサイドプロトタイプ汚染</th>
                          <td>
                            JavaScriptオブジェクトのプロパティを変更されることで、意図しない挙動を引き起こす脆弱性がないか検査します。
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </TableStyle>
                </DocumentStyle>
              </Common2PaneSectionLayout>
            </Section>
          </LazyLoad>

          <LazyLoad>
            <Interview title="SPA診断の事例インタビュー">
              <AssessmentVoiceList slugs={["mdm"]} />
            </Interview>
          </LazyLoad>

          <LazyLoad>
            <Section background="brand">
              <Common2PaneSectionLayout>
                <DocumentStyle>
                  <h2>料金</h2>
                  <p>
                    ソースコードの規模感に応じて診断日数を計算し、日数に応じたお見積りをご提案します。
                  </p>
                </DocumentStyle>
              </Common2PaneSectionLayout>
            </Section>
          </LazyLoad>

          <AssessmentDocContact />

          <LazyLoad>
            <Section>
              <Common2PaneSectionLayout>
                <DocumentStyle>
                  <h2>技術ブログ公開中</h2>
                  <p>
                    SPAを題材にセキュリティに関する技術ブログを公開中です。ぜひご覧ください。
                  </p>
                  <div className="Spa_BlogItem">
                    <a href="https://blog.flatt.tech/entry/spa_injection">
                      <img
                        className="Spa_BlogImage"
                        src="https://cdn-ak.f.st-hatena.com/images/fotolife/f/flattsecurity/20220406/20220406184000.png"
                        alt="SPA開発とセキュリティ - DOM based XSSを引き起こすインジェクションのVue, React, Angularにおける解説と対策"
                      />
                      https://blog.flatt.tech/entry/spa_injection
                    </a>
                  </div>
                </DocumentStyle>
              </Common2PaneSectionLayout>
            </Section>
          </LazyLoad>
        </div>
      </Assessment2PaneLayout>
    </>
  );
};

export const query = graphql`
  query SPAPage {
    spa_blackbox: file(
      relativePath: { eq: "assessment/spa_assessment_about1.png" }
    ) {
      publicURL
    }
    spa_whitebox: file(
      relativePath: { eq: "assessment/spa_assessment_about2.png" }
    ) {
      publicURL
    }
  }
`;

export default withContext(Component);
